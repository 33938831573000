

export const GET_EVENTS_WEBSITE_REQUEST = "GET_EVENTS_WEBSITE_REQUEST"
export const GET_EVENTS_WEBSITE_SUCCESS = "GET_EVENTS_WEBSITE_SUCCESS"
export const GET_EVENTS_WEBSITE_FAIL = "GET_EVENTS_WEBSITE_FAIL"



export const GET_EXE_COMM_REQUEST = "GET_EXE_COMM_REQUEST"
export const GET_EXE_COMM_SUCCESS = "GET_EXE_COMM_SUCCESS"
export const GET_EXE_COMM_FAIL = "GET_EXE_COMM_FAIL"


export const GET_ORG_REQUEST = "GET_ORG_REQUEST"
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS"
export const GET_ORG_FAIL = "GET_ORG_FAIL"

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"


export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST"
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL"

export const GET_ORG_DETAILS_REQUEST = "GET_ORG_DETAILS_REQUEST"
export const GET_ORG_DETAILS_SUCCESS = "GET_ORG_DETAILS_SUCCESS"
export const GET_ORG_DETAILS_FAIL = "GET_ORG_DETAILS_FAIL"

export const GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST"
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS"
export const GET_IMAGES_FAIL = "GET_IMAGES_FAIL"

export const GET_ALL_MAGAZINE_REQUEST = "GET_ALL_MAGAZINE_REQUEST"
export const GET_ALL_MAGAZINE_SUCCESS = "GET_ALL_MAGAZINE_SUCCESS"
export const GET_ALL_MAGAZINE_FAIL = "GET_ALL_MAGAZINE_FAIL"

export const GET_MAGAZINE_REQUEST = "GET_MAGAZINE_REQUEST"
export const GET_MAGAZINE_SUCCESS = "GET_MAGAZINE_SUCCESS"
export const GET_MAGAZINE_FAIL = "GET_MAGAZINE_FAIL"


export const GET_NEWS1_REQUEST = "GET_NEWS1_REQUEST"
export const GET_NEWS1_SUCCESS = "GET_NEWS1_SUCCESS"
export const GET_NEWS1_FAIL = "GET_NEWS1_FAIL"

export const CONTACT_FORM_REQUEST = "CONTACT_FORM_REQUEST"
export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS"
export const CONTACT_FORM_FAIL = "CONTACT_FORM_FAIL"
export const CONTACT_FORM_RESET = "CONTACT_FORM_RESET"

export const QUERY_FORM_REQUEST = "QUERY_FORM_REQUEST"
export const QUERY_FORM_SUCCESS = "QUERY_FORM_SUCCESS"
export const QUERY_FORM_FAIL = "QUERY_FORM_FAIL"
export const QUERY_FORM_RESET = "QUERY_FORM_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"