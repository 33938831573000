    export const CREATE_WORKSHOP_BOOKING_FAIL = "CREATE_WORKSHOP_BOOKING_FAIL";
    export const  CREATE_WORKSHOP_BOOKING_REQUEST = "CREATE_WORKSHOP_BOOKING_REQUEST";
    export const CREATE_WORKSHOP_BOOKING_SUCCESS = "CREATE_WORKSHOP_BOOKING_SUCCESS";
    export const CREATE_WORKSHOP_BOOKING_RESET = "CREATE_WORKSHOP_BOOKING_RESET";

    export const GET_WORKSHOPS_WEBSITE_FAIL = "GET_WORKSHOPS_WEBSITE_FAIL";
    export const GET_WORKSHOPS_WEBSITE_REQUEST = "GET_WORKSHOPS_WEBSITE_REQUEST";
    export const GET_WORKSHOPS_WEBSITE_SUCCESS = "GET_WORKSHOPS_WEBSITE_SUCCESS";

    export const GET_WORKSHOP_FAIL = "GET_WORKSHOP_FAIL";
    export const GET_WORKSHOP_REQUEST ="GET_WORKSHOP_REQUEST";
    export const GET_WORKSHOP_SUCCESS= "GET_WORKSHOP_SUCCESS";

    export const CLEAR_ERROR= "CLEAR_ERROR";