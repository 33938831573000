import { CLEAR_ERROR, CONTACT_FORM_FAIL, CONTACT_FORM_REQUEST, CONTACT_FORM_RESET, CONTACT_FORM_SUCCESS, GET_ALL_MAGAZINE_FAIL, GET_ALL_MAGAZINE_REQUEST, GET_ALL_MAGAZINE_SUCCESS, GET_EVENTS_WEBSITE_FAIL, GET_EVENTS_WEBSITE_REQUEST, GET_EVENTS_WEBSITE_SUCCESS, GET_EXE_COMM_FAIL, GET_EXE_COMM_REQUEST, GET_EXE_COMM_SUCCESS, GET_GALLERY_FAIL, GET_GALLERY_REQUEST, GET_GALLERY_SUCCESS, GET_IMAGES_FAIL, GET_IMAGES_REQUEST, GET_IMAGES_SUCCESS, GET_MAGAZINE_FAIL, GET_MAGAZINE_REQUEST, GET_MAGAZINE_SUCCESS, GET_NEWS1_FAIL, GET_NEWS1_REQUEST, GET_NEWS1_SUCCESS, GET_NEWS_FAIL, GET_NEWS_REQUEST, GET_NEWS_SUCCESS, GET_ORG_DETAILS_FAIL, GET_ORG_DETAILS_REQUEST, GET_ORG_DETAILS_SUCCESS, GET_ORG_FAIL, GET_ORG_REQUEST, GET_ORG_SUCCESS, QUERY_FORM_FAIL, QUERY_FORM_REQUEST, QUERY_FORM_RESET, QUERY_FORM_SUCCESS } from '../constants/eventsConstant';

export const getEvents = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_EVENTS_WEBSITE_REQUEST:
    case GET_EXE_COMM_REQUEST:
    case GET_ORG_REQUEST:
    case GET_GALLERY_REQUEST:
    case GET_ORG_DETAILS_REQUEST:
    case GET_NEWS_REQUEST:
    case GET_ALL_MAGAZINE_REQUEST:
    case GET_MAGAZINE_REQUEST:
    case GET_NEWS1_REQUEST:

      return {
        loading: true,
      };

    case GET_EVENTS_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.students,
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload.allNews,
      };

    case GET_NEWS1_SUCCESS:
      return {
        ...state,
        loading: false,
        news1: action.payload.news,
      };

    case GET_ALL_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        newsLetters: action.payload.newsLetters,
      };
    case GET_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        newsLetter: action.payload.newsLetter,
      };
    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        gallerys: action.payload.gallerys,
      };
    case GET_EXE_COMM_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.payload.members,
      };

    case GET_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        orgs: action.payload.orgs,
        pagePerview: ''
      };
    case GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        org: action.payload.org,
      };


    case GET_EVENTS_WEBSITE_FAIL:
    case GET_EXE_COMM_FAIL:
    case GET_ORG_FAIL:
    case GET_GALLERY_FAIL:
    case GET_ORG_DETAILS_FAIL:
    case GET_NEWS_FAIL:
    case GET_ALL_MAGAZINE_FAIL:
    case GET_MAGAZINE_FAIL:
    case GET_NEWS1_FAIL:
      return {
        ...state,
        loading: false,
        students: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const getGallery = (state = { data: {} }, action) => {
  switch (action.type) {

    case GET_GALLERY_REQUEST:

      return {
        loading: true,
      };


    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        gallerys: action.payload.gallerys,
      };


    case GET_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
        gallerys: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getImages = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_IMAGES_REQUEST:

      return {
        loading: true,
      };

    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        images: action.payload.images,
      };



    case GET_IMAGES_FAIL:
      return {
        ...state,
        loading: false,
        images: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const contactReducer = (state = { data: {} }, action) => {
  switch (action.type) {

    case CONTACT_FORM_REQUEST:
    case QUERY_FORM_REQUEST:

      return {
        loading: true,
      };


    case CONTACT_FORM_SUCCESS:
    case QUERY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };

    case CONTACT_FORM_FAIL:
    case QUERY_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONTACT_FORM_RESET:
    case QUERY_FORM_RESET:
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};