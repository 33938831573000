import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import './pages/HomePage.css'

// import Header from './components/Header/header'
// import Footer from './components/Footer/Footer' 

import Skeleton1 from './components/Skeleton.js'
import SkeletonBanner from './components/SkeletonBanner.js';

const Footer = lazy(() => import('./components/Footer/Footer'));
const HomePage = lazy(() => import('./pages/HomePage.js'));
const ContactPage = lazy(() => import('./pages/ContactPage.js'));
const AboutPage = lazy(() => import('./pages/AboutPage.js'));
const OurTeamPage = lazy(() => import('./pages/OurTeamPage.js'));
const GalleryPage = lazy(() => import('./pages/GalleryPage.js'));
// const OrgDirPage =  from './pages/OrgDirPage.js'
// const OrgPage =  from './pages/OrgPage.js'
const NewsPage = lazy(() => import('./pages/NewsPage.js'));
const NewsIdPage = lazy(() => import('./pages/NewsIdPage.js'));

const ALLEvents = lazy(() => import('./pages/Events/ALLEvents.js'));
const LifetimeAchieve = lazy(() => import('./pages/LifetimeAchieve.js'));
// const MagazineList =  lazy(() => import('./pages/MagazineView.js'));
// const MagazinePage =  lazy(() => import('./pages/MagazinePage.js'));

// terms and conditions 
const TermsConditionsPage = lazy(() => import('./pages/TermsConditionsPage.js'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage.js'));
const RefundPage = lazy(() => import('./pages/RefundPage.js'));


// const AllWorkshopsPage = lazy(() => import('./pages/workshop/AllWorkshopsPage'));
// const WorkshopBookingsPage = lazy(() => import('./pages/workshop/WorkshopBookingsPage'));
const WorkshopPage = lazy(() => import('./pages/workshop/WorkshopPage.js'));


const Header = lazy(() => import('./components/Header/header'));

function App() {
  window.scrollTo(0, 0);

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Skeleton1 />}>
          <Header />
        </Suspense>
        <Routes>


          <Route path="/" element={<Suspense fallback={<SkeletonBanner />}><HomePage /></Suspense>} />
          <Route path="/contact" element={<Suspense fallback={< SkeletonBanner />}> <ContactPage /> </Suspense>} />
          <Route path="/about" element={<Suspense fallback={<SkeletonBanner />}> <AboutPage /> </Suspense>} />
          <Route path="/news" element={<Suspense fallback={< SkeletonBanner />}><NewsPage /></Suspense>} />
          <Route path="/news/:id" element={<Suspense fallback={< SkeletonBanner />}><NewsIdPage /></Suspense>} />

          <Route path="/team" element={<Suspense fallback={< SkeletonBanner />}><OurTeamPage /></Suspense>} />
          <Route path="/gallery" element={<Suspense fallback={< SkeletonBanner />}><GalleryPage /></Suspense>} />

          <Route path="/lifetime-achievements" element={<Suspense fallback={< SkeletonBanner />}><LifetimeAchieve /></Suspense>} />

          {/* Orgs */}
          {/* <Route path="/org" element={<Suspense fallback={< SkeletonBanner />}><OrgDirPage/></Suspense>} />
            <Route path="/org/:id" element={<Suspense fallback={< SkeletonBanner />}><OrgPage/></Suspense>} /> */}

          {/* Magazine */}
          {/* <Route path="/magazines" element={<Suspense fallback={< SkeletonBanner />}><MagazineList /></Suspense>} />
        <Route path="/magazine/:id" element={<Suspense fallback={< SkeletonBanner />}><MagazinePage /></Suspense>} /> */}

          {/* Events */}
          <Route path="/all-events" element={<Suspense fallback={< SkeletonBanner />}><ALLEvents /></Suspense>} />

          {/* terms and conditions */}
          <Route path="/terms&conditions" element={<Suspense fallback={< SkeletonBanner />}><TermsConditionsPage /></Suspense>} />
          <Route path="/privacy-policy" element={<Suspense fallback={< SkeletonBanner />}><PrivacyPolicyPage /></Suspense>} />
          <Route path="/refund-cancellation-policy" element={<Suspense fallback={< SkeletonBanner />}><RefundPage /></Suspense>} />

          {/* workshops code */}
          {/* <Route path="/workshop/:workshopId" element={<Suspense fallback={< SkeletonBanner />}><WorkshopPage /></Suspense>} /> */}
          <Route path="/workshops" element={<Suspense fallback={< SkeletonBanner />}><WorkshopPage /></Suspense>} />
          {/* <Route path={`/workshop-registration/:workshopId`} element={<Suspense fallback={< SkeletonBanner />}><WorkshopBookingsPage /></Suspense>} /> */}

          <Route path="*" element={<Suspense fallback={< SkeletonBanner />}><HomePage /></Suspense>} />

        </Routes>
        <Suspense fallback={< SkeletonBanner />}><Footer /></Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
