import React from 'react'
import './Skeleton.css'
const Skeleton = () => {
  return (
    <div className='skelBox'>
        <div></div>
        <div></div>

    </div>
  )
}

export default Skeleton