import React from 'react'
import './Skeleton.css'

const Skeleton = () => {
  return (
    <div className='skelBanner'>
        <div></div>
        <div>
           <div></div>
           <div>
              <div></div>
              <div></div>
           </div>
        </div>
    </div>
  )
}

export default Skeleton