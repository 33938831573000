import {

    CLEAR_ERROR,

    GET_WORKSHOP_REQUEST,
    GET_WORKSHOP_SUCCESS,
    GET_WORKSHOP_FAIL,

    GET_WORKSHOPS_WEBSITE_REQUEST,
    GET_WORKSHOPS_WEBSITE_FAIL,
    GET_WORKSHOPS_WEBSITE_SUCCESS,

    CREATE_WORKSHOP_BOOKING_REQUEST,
    CREATE_WORKSHOP_BOOKING_SUCCESS,
    CREATE_WORKSHOP_BOOKING_FAIL,
    CREATE_WORKSHOP_BOOKING_RESET,

} from '../constants/workshopContstant'


export const bookingWorkshop = (state = { data: {} }, action) => {
    switch (action.type) {


        case CREATE_WORKSHOP_BOOKING_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case CREATE_WORKSHOP_BOOKING_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                html: action.payload.html
            };

        case CREATE_WORKSHOP_BOOKING_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };


        case CREATE_WORKSHOP_BOOKING_RESET:

            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getWorkshop = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_WORKSHOP_REQUEST:
        case GET_WORKSHOPS_WEBSITE_REQUEST:
            return {
                loading: true,
            };



        case GET_WORKSHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                workshop: action.payload.workshop,
                // totalFee : action.payload.totalFee


            };

        case GET_WORKSHOPS_WEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                workshops: action.payload.workshops,
                // totalFee : action.payload.totalFee


            };

        case GET_WORKSHOP_FAIL:
        case GET_WORKSHOPS_WEBSITE_FAIL:
            return {
                ...state,
                loading: false,
                workshops: null,
                workshop: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};