import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import {  getEvents, getImages,getGallery, contactReducer } from './reducers/fileReducer';
import { bookingWorkshop, getWorkshop } from './reducers/workshopReducer';



const reducer = combineReducers({
    get:getEvents,
    images: getImages,
    getGallery,
    contact:contactReducer,

    booking:bookingWorkshop,
    getWorkshops:getWorkshop


});

const intitialeState = {
    
}; 

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
